import SkillsGrid from "./SkillsGrid";
import AboutIntro from "./AboutIntro";

export default function About() {
  return (
    <div>
      <AboutIntro></AboutIntro>
      <SkillsGrid />
    </div>
  );
}
