import "../styles/Identity.css";
import { ReactTyped } from "react-typed";

export default function Identity() {
  return (
    <div className="identity">
      <div className="text-container">
        <h2 className="heading">
          Hi There! <span className="wave">👋</span>
        </h2>
        <h2 className="heading">I'M ARSEN DUZGUN</h2>
        <ReactTyped
          strings={[
            '<span class="typed-style">Software Engineer</span>',
            '<span class="typed-style">ML Engineer</span>',
          ]}
          typeSpeed={100}
          backSpeed={50}
          loop
          showCursor={true}
          cursorChar="|"
        />
      </div>
      <img className="image" src={"images/identity.png"} alt="Identity"></img>
    </div>
  );
}
