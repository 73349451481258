import Socials from "./Socials";
import Identity from "./Identity";
import HomeIntro from "./HomeIntro";

export default function Home() {
  return (
    <div>
      <Identity></Identity>
      <HomeIntro></HomeIntro>
      <Socials></Socials>
    </div>
  );
}
