import "../styles/SkillCard.css"; // Importing only SkillCard CSS

export default function SkillCard({ skill }) {
  return (
    <div className="skill-card">
      <img src={`images/skills/${skill.image}`} alt={skill.title} />
      <h3>{skill.name}</h3>
    </div>
  );
}
