import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Projects from "./components/Projects";
import Header from "./components/Header";
import "./App.css";

function SmoothScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const timer = setTimeout(handleScroll, 50); // Delay scrolling to ensure full render
    return () => clearTimeout(timer); // Clean up timer on unmount
  }, [pathname]);

  return null;
}

function BackgroundUpdater({ totalStars, setMaxStars }) {
  useEffect(() => {
    const updateStars = () => {
      const area = window.innerWidth * window.innerHeight;
      const largestArea = 1920 * 1080; // Define the largest area for scaling
      const maxStars = Math.ceil((totalStars * area) / largestArea);
      setMaxStars(maxStars);
    };

    window.addEventListener("resize", updateStars);
    updateStars(); // Initial calculation

    return () => window.removeEventListener("resize", updateStars);
  }, [setMaxStars, totalStars]);

  return null;
}

function AppContent({ totalStars, setAllStars }) {
  const { pathname } = useLocation(); // Hook to get the current route path

  useEffect(() => {
    const generateStars = () => {
      const stars = [];
      for (let i = 0; i < totalStars; i++) {
        const x = Math.random() * 96 + 2; // Use percentage for x
        const y = Math.random() * 96 + 2; // Use percentage for y

        stars.push(
          <div
            key={`${pathname}-${i}`} // Unique key based on route
            className="star"
            style={{
              left: `${x}%`,
              top: `${y}%`,
              width: "1.5px",
              height: "1.5px",
            }}
          />
        );
      }
      return stars;
    };

    setAllStars(generateStars());
  }, [pathname, totalStars, setAllStars]);

  return (
    <>
      <SmoothScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Navigate to="/" />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
}

function App() {
  const [allStars, setAllStars] = useState([]);
  const [maxStars, setMaxStars] = useState(0);
  const totalStars = 300;

  return (
    <div>
      <div className="background">
        {allStars.slice(0, maxStars)} {/* Adjust visible stars */}
      </div>
      <Router>
        <BackgroundUpdater totalStars={totalStars} setMaxStars={setMaxStars} />
        <AppContent totalStars={totalStars} setAllStars={setAllStars} />
      </Router>
    </div>
  );
}

export default App;
