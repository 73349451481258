import { useState, useEffect } from "react";
import SkillCard from "./SkillCard";
import "../styles/SkillsGrid.css";

export default function SkillsGrid() {
  const [skills, setSkills] = useState([]);

  useEffect(() => {
    fetch("skills.json")
      .then((response) => response.json())
      .then((data) => setSkills(data));
  }, []);

  return (
    <div className="skills-grid">
      <h1 className="heading">My Skillset</h1>
      <div className="skill">
        {skills.map((skill) => (
          <SkillCard key={skill.id} skill={skill} />
        ))}
      </div>
    </div>
  );
}
