import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/HeaderNarrow.css";

export default function HeaderNarrow() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const headerRef = useRef(null); // Reference to the header element

  // Close dropdown when the route changes
  useEffect(() => {
    setIsOpen(false); // Close the menu when the route changes
  }, [location.pathname]);

  // Close dropdown when clicking outside the header
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        setIsOpen(false); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (event, path) => {
    if (location.pathname === path) {
      event.preventDefault(); // Prevent navigation if the route is the same
    }
    setIsOpen(false); // Close the dropdown regardless
  };

  return (
    <div className="header-narrow" ref={headerRef}>
      <div className="dropdown-icon-container">
        <div
          className={`dropdown-icon ${isOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <div className="bar top-bar"></div>
          <div className="bar middle-bar"></div>
          <div className="bar bottom-bar"></div>
        </div>
      </div>
      <nav className={`dropdown-menu ${isOpen ? "show" : ""}`}>
        <Link
          to="/"
          className="menu-link"
          onClick={(e) => handleNavigation(e, "/")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#e8eaed"
            className="menu-icon"
          >
            <path d="M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-240h-80v240H160Zm320-350Z" />
          </svg>
          Home
        </Link>
        <Link
          to="/about"
          className="menu-link"
          onClick={(e) => handleNavigation(e, "/about")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#e8eaed"
            className="menu-icon"
          >
            <path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z" />
          </svg>
          About
        </Link>
        <Link
          to="/projects"
          className="menu-link"
          onClick={(e) => handleNavigation(e, "/projects")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#e8eaed"
            className="menu-icon"
          >
            <path d="M160-120q-33 0-56.5-23.5T80-200v-440q0-33 23.5-56.5T160-720h160v-80q0-33 23.5-56.5T400-880h160q33 0 56.5 23.5T640-800v80h160q33 0 56.5 23.5T880-640v440q0 33-23.5 56.5T800-120H160Zm240-600h160v-80H400v80Zm400 360H600v80H360v-80H160v160h640v-160Zm-360 0h80v-80h-80v80Zm-280-80h200v-80h240v80h200v-200H160v200Zm320 40Z" />
          </svg>
          Projects
        </Link>
      </nav>
    </div>
  );
}
