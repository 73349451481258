import { useState, useEffect } from "react";
import "../styles/ProjectGrid.css";
import ProjectCard from "./ProjectCard";

export default function ProjectGrid() {
  const [projects, setProjects] = useState([]);

  // Fetch projects.json when the component mounts
  useEffect(() => {
    fetch("projects.json")
      .then((response) => response.json())
      .then((data) => setProjects(data));
  }, []);

  return (
    <div className="projects-grid">
      <div className="text-container">
        <h1 className="heading">My Projects</h1>
        <p className="paragraph">Here are a few projects I've worked on.</p>
      </div>
      <div className="project">
        {projects.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </div>
    </div>
  );
}
