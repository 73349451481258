import "../styles/ProjectCard.css";

export default function ProjectCard({ project }) {
  return (
    <div className="project-card" key={project.id}>
      <img
        className="image"
        src={`images/projects/${project.image}`}
        alt={project.title}
      />
      <h2 className="heading">{project.title}</h2>
      <p className="paragraph">{project.description}</p>
      <div className="button-wrapper">
        <a href={project.github} target="_blank" rel="noopener noreferrer">
          <button className="button">GitHub</button>
        </a>
      </div>
    </div>
  );
}
