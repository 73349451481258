import "../styles/AboutIntro.css";

export default function AboutIntro() {
  return (
    <div className="about-intro">
      <div className="text-container">
        <h1 className="heading">About Me</h1>
        <p className="paragraph">
          With a BSc in <span className="keyword">Computer Engineering</span>{" "}
          and an MSc in <span className="keyword">Data Science</span>, I offer a
          well-rounded expertise at the intersection of{" "}
          <span className="keyword">software engineering</span> and{" "}
          <span className="keyword">machine learning</span>. My passion lies in
          developing robust, <span className="keyword">data-driven</span>{" "}
          applications that solve complex challenges and deliver measurable
          impact.
        </p>
        <p className="paragraph">
          In previous roles, I have contributed to automating workflows,
          optimizing systems, and delivering actionable insights. My work
          included building <span className="keyword">user interfaces</span>,
          improving <span className="keyword">backend performance</span>,
          building <span className="keyword">machine learning models</span>, and
          streamlining <span className="keyword">data processing</span>. I also
          collaborated effectively within teams, driving efficiency and
          delivering meaningful results.
        </p>
        <p className="paragraph">
          I’m always eager to explore new tech trends and refine my skills
          through personal projects, including{" "}
          <span className="keyword">model deployment</span> and developing{" "}
          <span className="keyword">algorithm-driven</span> applications.
          Whether working independently or as part of a team, I’m driven by a
          commitment to innovation and a focus on creating impactful solutions.
        </p>
      </div>
      <img
        className="image"
        src={"images/about_intro.png"}
        alt="AboutIntro"
      ></img>
    </div>
  );
}
