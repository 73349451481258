import "../styles/Socials.css";

export default function Socials() {
  return (
    <div className="socials">
      <p>Feel free to connect with me on</p>
      <div className="icon-container">
        <a
          href="https://github.com/arsenduzgun"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="icon"
          >
            <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.387.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61-.546-1.387-1.333-1.757-1.333-1.757-1.086-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.775.418-1.305.762-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.47-2.381 1.236-3.221-.123-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23a11.513 11.513 0 0 1 3.003-.404c1.018.005 2.042.137 3.003.404 2.292-1.553 3.3-1.23 3.3-1.23.653 1.653.241 2.874.118 3.176.768.84 1.236 1.911 1.236 3.221 0 4.61-2.804 5.625-5.475 5.92.43.37.823 1.102.823 2.222 0 1.606-.014 2.896-.014 3.293 0 .321.218.694.825.576C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12" />
          </svg>
        </a>
        <a
          href="https://www.linkedin.com/in/arsenduzgun/"
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="icon"
          >
            <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.761 0 5-2.239 5-5v-14c0-2.761-2.239-5-5-5zm-11 19h-3v-10h3v10zm-1.5-11.268c-.966 0-1.75-.804-1.75-1.732 0-.929.784-1.732 1.75-1.732s1.75.804 1.75 1.732c0 .929-.784 1.732-1.75 1.732zm13.5 11.268h-3v-5.604c0-1.337-.026-3.062-1.868-3.062-1.869 0-2.156 1.46-2.156 2.965v5.701h-3v-10h2.881v1.367h.041c.4-.756 1.379-1.555 2.838-1.555 3.034 0 3.594 2 3.594 4.604v5.584z" />
          </svg>
        </a>
      </div>
    </div>
  );
}
