import React, { useEffect, useState } from "react";
import HeaderWide from "./HeaderWide";
import HeaderNarrow from "./HeaderNarrow";

export default function Header() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 450);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 450);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return <>{isSmallScreen ? <HeaderNarrow /> : <HeaderWide />}</>;
}
